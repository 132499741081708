import React from "react";
import { Button } from "src/theme/elements";

const DownloadAppButton = () => {
  let env = "latest";

  if (global.location && global.location.hostname.indexOf("staging") === 0) {
    env = "staging";
  }

  return <Button itemProp="url" to={`https://app.appspector.com/support/desktop-app/${env}/mac`}>
    Download MacOS app
  </Button>;
};

export default DownloadAppButton;
