import styled, { createGlobalStyle } from "styled-components";

export const PageReset = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  svg {
    display: block;
  }
`;
export const StyledPageContainer = styled.div`
  overflow: hidden;
`;